import React, { memo, useEffect } from "react";
import styled from "styled-components";
import LanguageIcon from "@material-ui/icons/Language";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getCurrentCurrency } from "../utils/currencyFormatter";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [user] = useAuthState(auth);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <div
        className="scrollToTop"
        style={{ display: visible ? "block" : "none" }}
      >
        <FooterButton onClick={scrollToTop}>Back to top</FooterButton>
      </div>
      <div className="first_footer-part">
        <Container>
          <div className="wrapper-div">
            <h2>Get to Know Us</h2>
            <FooterLink href="/careers">Careers</FooterLink>
            <FooterLink href="/blog">Blog</FooterLink>
            <FooterLink href="/about">About Amazon</FooterLink>
            <FooterLink href="/ir">Investor Relations</FooterLink>
            <FooterLink href="/devices">Amazon Devices</FooterLink>
          </div>

          <div className="wrapper-div">
            <h2>Make Money with Us</h2>
            <FooterLink href="/sell">Sell products on Amazon</FooterLink>
            <FooterLink href="/business">Sell on Amazon Business</FooterLink>
            <FooterLink href="/apps">Sell apps on Amazon</FooterLink>
            <FooterLink href="/affiliate">Become an Affiliate</FooterLink>
            <FooterLink href="/advertise">Advertise Your Products</FooterLink>
            <FooterLink href="/publish">Self-Publish with Us</FooterLink>
            <FooterLink href="/hub">Host an Amazon Hub</FooterLink>
            <FooterLink href="/make-money">
              See More Make Money with Us
            </FooterLink>
          </div>

          <div className="wrapper-div">
            <h2>Amazon Payment Products</h2>
            <FooterLink href="/business-card">Amazon Business Card</FooterLink>
            <FooterLink href="/shop-points">Shop with Points</FooterLink>
            <FooterLink href="/reload">Reload Your Balance</FooterLink>
            <FooterLink href="/currency">Amazon Currency Converter</FooterLink>
          </div>

          <div className="wrapper-div">
            <h2>Let Us Help You</h2>
            <FooterLink href="/covid">Amazon and COVID-19</FooterLink>
            <FooterLink href="/account">Your Account</FooterLink>
            <FooterLink href="/orders">Your Orders</FooterLink>
            <FooterLink href="/shipping">Shipping Rates & Policies</FooterLink>
            <FooterLink href="/returns">Returns & Replacements</FooterLink>
            <FooterLink href="/devices">
              Manage Your Content and Devices
            </FooterLink>
            <FooterLink href="/assistant">Amazon Assistant</FooterLink>
            <FooterLink href="/help">Help</FooterLink>
          </div>
        </Container>
      </div>

      <div className="middle_footer-part">
        <div className="middle_part-container">
          <Link to="/">
            <img
              className="amazon-logo"
              src="http://pngimg.com/uploads/amazon/amazon_PNG11.png"
              alt="amazon-logo"
            />
          </Link>
          <div className="second-wrap">
            <div>
              <LanguageIcon className="language" /> English
            </div>
            <div> {getCurrentCurrency(user?.email ?? "siva@digibank.com").code} Currency</div>
            <div>
              <img
                className="us-flag"
                src="https://pngimg.com/uploads/flags/flags_PNG14592.png"
                alt="usa flag"
              />
              United States
            </div>
          </div>
        </div>
      </div>

      <div className="first_footer-part second_footer-part">
        <Container className="modified_container">
          <div className="wrapper-div">
            <h2>Amazon Music</h2>
            <FooterLink href="/music">Stream millions of songs</FooterLink>
          </div>
          {/* Additional service sections follow the same pattern */}
        </Container>
      </div>

      <ConditionsOfUse>
        <FooterLink href="/conditions">Conditions of Use</FooterLink>
        <FooterLink href="/privacy">Privacy Notice</FooterLink>
        <FooterLink href="/ads">Interest-Based Ads</FooterLink>
        <CopyrightLink href="/copyright">
          © 1996-2021, Amazon.com, Inc. or its affiliates
        </CopyrightLink>
      </ConditionsOfUse>
    </Wrapper>
  );
};

export default memo(Footer);

const FooterLink = styled.a`
  margin-top: 10px;
  color: #ddd;
  display: block;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  font-family: Arial, sans-serif;

  &:hover {
    text-decoration: underline;
  }
`;

const CopyrightLink = styled(FooterLink)`
  cursor: default;
  &:hover {
    text-decoration: none;
  }
`;

const FooterButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 13px;
  padding: 0;
  font-family: inherit;
  width: 100%;
  height: 100%;

  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  font-family: "Amazon Ember", Arial, sans-serif;
  background: #232f3e;
  margin: 0 auto;
  height: auto;
  color: white;

  h2 {
    font-size: 0.85rem;
    margin-bottom: 10px;
  }

  .scrollToTop {
    display: flex;
    background-color: #37475a;
    margin-bottom: 40px;
    transition: 0.4s;
    display: block;
    text-align: center;
    color: white;
    padding: 15px 0;
    line-height: 19px;
    font-size: 13px;
    border: 1px dotted grey;

    &:hover {
      background: #232f3e;
    }

    span {
      cursor: pointer;
    }
  }

  .first_footer-part {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    border-bottom: 1px solid #37475a;
  }

  .first_footer-part.second_footer-part {
    background: #131921;
    border-bottom: none;
    padding: 20px;

    .wrapper-div {
      width: 120px;
      min-width: 110px;
      margin-top: 5px;
      height: 80px;
      overflow: hidden;
    }

    .modified_container {
      min-height: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: hidden;
    }

    h2 {
      font-size: 0.7rem;
      margin-bottom: 0px;
    }

    a {
      font-size: 12px;
      margin-top: 0px;
      color: #ddd;
      line-height: 1.3;
    }
  }

  .middle_footer-part {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-height: 320px;
    margin-bottom: 20px;

    img.amazon-logo {
      display: flex;
      align-items: center;
      height: 50px;
      width: 100px;
      margin-right: 15px;
      object-fit: contain;
      transition: 0.4s;
      padding: 10px 15px;
    }

    img.us-flag {
      width: 30px;
      height: 20px;
      margin-right: 10px;
    }

    .language {
      margin-right: 10px;
    }

    .middle_part-container {
      width: 100%;
      min-height: 100px;
      font-size: 0.8em;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 789px) {
        flex-direction: column;
      }

      div.second-wrap {
        width: 450px;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 689px) {
          width: 100%;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 0px auto;
        }

        div {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 10px;
          height: 50px;
          min-width: 170px;
          box-shadow: 7px -1px 24px 5px rgba(18, 25, 53, 0.75);
          margin-right: 10px;
          padding: 10px 15px;
          border: 1px solid white;

          @media (max-width: 600px) {
            min-width: 250px;
            margin: 10px auto;
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  width: 1000px;
  max-width: 1000px;
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;

  .wrapper-div {
    width: 200px;
    margin-top: 25px;
    margin-right: 10px;
    padding-bottom: 30px;
  }
`;

const ConditionsOfUse = styled.div`
  display: flex;
  justify-content: center;
  background: #131921;
  padding: 40px 20px;
  line-height: 19px;
  font-size: 1.2px;
  flex-wrap: wrap;

  @media (max-width: 568px) {
    padding: 20px;
    padding-top: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 40px;

    a {
      margin: 3px;
    }
  }

  a {
    margin-right: 10px;
    font-size: 0.7rem;
    color: white;
  }
`;
