import { CurrencyType } from "../types/currency.types";

export const getCurrentCurrency = (userEmail: string): CurrencyType => {
  const isBHD = userEmail?.toLowerCase().endsWith('@aub.bh') ?? false;

  return isBHD 
    ? { code: 'BHD', symbol: 'BHD', decimals: 3 }
    : { code: 'AED', symbol: 'AED', decimals: 2 };
};

export const formatCurrencyForLogin = (amount: number, email:string): string => {
  const currency = getCurrentCurrency(email);
  
  // Handle number formatting
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);
  
  // Format with proper decimal places
  const formattedNumber = absAmount.toFixed(currency.decimals);
  const [integerPart, decimalPart] = formattedNumber.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  return `${isNegative ? '-' : ''}${currency.symbol} ${formattedInteger}.${decimalPart}`;
};